import { lazy } from "react";

const Home = lazy(() => import("pages/home"));
const Ride = lazy(() => import("pages/Ride"));
const Booking = lazy(() => import("pages/Booking"));
const Account = lazy(() => import("pages/Account"));
const SignIn = lazy(() => import("pages/SignIn"));
const SignUp = lazy(() => import("pages/SignUp"));
const AccountEdit = lazy(() => import("pages/AccountEdit"));
const RideDetail = lazy(() => import("pages/RideDetail"));
const ArticleDetail = lazy(() => import("pages/ArticleDetail"));

const BookingProcess = lazy(() => import("pages/BookingProcess"));
const PaymentSummary = lazy(() => import("pages/PaymentSummary"));
const BookingDetail = lazy(() => import("pages/BookingDetail"));
const BookingQrCode = lazy(() => import("pages/QrCode"));

const BikesByDate = lazy(() => import("pages/BikesByDate"));
const NotificationDetail = lazy(() => import("pages/NotificationDetail"));
const Notification = lazy(() => import("pages/Notification"));
const PencarianMotor = lazy(() => import("pages/PencarianMotor"));

export const Routes = {
  home: {
    name: "Home",
    path: "/",
    component: Home,
  },
  ride: {
    name: "Ride",
    path: "/ride",
    component: Ride,
  },
  booking: {
    name: "Booking",
    path: "/booking",
    component: Booking,
  },
  bookingDetail: {
    name: "Booking",
    path: "/booking/detail/:status/:id",
    component: BookingDetail,
  },
  bookingProcess: {
    name: "Booking Process",
    path: "/booking/process/:id",
    component: BookingProcess,
  },
  bookingQrCode: {
    name: "Booking QrCode",
    path: "/booking/qr/:id",
    component: BookingQrCode,
  },
  account: {
    name: "Account",
    path: "/profile",
    component: Account,
  },
  signin: {
    name: "SignIn",
    path: "/signin",
    component: SignIn,
  },
  signup: {
    name: "SignUp",
    path: "/register",
    component: SignUp,
  },
  profileEdit: {
    name: "Profile",
    path: "/profile/edit",
    component: AccountEdit,
  },
  rideDetail: {
    name: "Ride",
    path: "/ride/vehicle/:id",
    component: RideDetail,
  },
  payment: {
    name: "Payment",
    path: "/payment/:id",
    component: PaymentSummary,
  },
  articleDetal: {
    name: "Article Detail",
    path: "/article/:id",
    component: ArticleDetail,
  },
  rideByDate: {
    name: "Ride By Date",
    path: "/book/ride",
    component: BikesByDate,
  },
  notif: {
    name: "Notif",
    path: "/inbox",
    component: Notification,
  },
  notifDetail: {
    name: "Notif Detail",
    path: "/inbox/:id",
    component: NotificationDetail,
  },
  pencarian: {
    name: "Pencarian Motor",
    path: "/ride/search",
    component: PencarianMotor,
  },
};

export const showTab = [
  Routes.home.path,
  Routes.ride.path,
  Routes.account.path,
  Routes.booking.path,
  Routes.signin.path,
];
