import { getProfile } from 'redux/actions/authAction';
// import { getBrandData } from 'redux/actions/productAction';
import Cookies from "js-cookie";

const getInitialState = () => (dispatch) => {
  const token = Cookies.get("token") || "";

  if (token) {
    dispatch(getProfile());

  }
  // dispatch(getProductPopularData());
  // dispatch(getArticleData());
  // dispatch(getBannerData())
};

export default getInitialState;
