import {
  SET_BOOKING_DATE,
} from "../actionType";

const article = (
  state = {
    bookingData: {
      startDate: '',
      endDate: '',
      diff: '',
      full: '',
      byDate: false,
    },
  },
  action
) => {
  const { type, payload } = action;
  switch (type) {
  
    case SET_BOOKING_DATE: {
      return {
        ...state,
        bookingData: {...state.bookingData, ...payload},
      };
    }
    default:
      return state;
  }
};

export default article;
