import {
  PRODUCT_PROCESS,
  PRODUCT_SUCCESS,
  PRODUCT_FAILED,
  PRODUCT_POPULAR_PROCESS,
  PRODUCT_POPULAR_SUCCESS,
  PRODUCT_POPULAR_FAILED,
  BRAND_PROCESS,
  BRAND_SUCCESS,
  BRAND_FAILED,
} from "../actionType";

const products = (
  state = {
    productData: {},
    productPopularData: {},
    brandData: {},
    brandTypeData: {},
    error: false,
  },
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case PRODUCT_POPULAR_PROCESS: {
      return {
        ...state,
        error: false,
      };
    }
    case PRODUCT_POPULAR_SUCCESS: {
      return {
        ...state,
        productPopularData: {...payload},
      };
    }
    case PRODUCT_POPULAR_FAILED: {
      return {
        ...state,
        error: payload,
      };
    }
    case PRODUCT_PROCESS: {
      return {
        ...state,
        error: false,
      };
    }
    case PRODUCT_SUCCESS: {
      return {
        ...state,
        productData: {...payload},
      };
    }
    case PRODUCT_FAILED: {
      return {
        ...state,
        error: payload,
      };
    }
    case BRAND_PROCESS: {
      return {
        ...state,
      };
    }
    case BRAND_SUCCESS: {
      return {
        ...state,
        brandData: {...payload},
      };
    }
    case BRAND_FAILED: {
      return {
        ...state,
        error: payload,
      };
    }
    default:
      return state;
  }
};

export default products;
