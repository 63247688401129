import {
  BANNER_PROCESS,
  BANNER_SUCCESS,
  BANNER_FAILED,
} from "../actionType";

const banner = (
  state = {
    bannerData: [],
    error: false,
  },
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case BANNER_PROCESS: {
      return {
        ...state,
      };
    }
    case BANNER_SUCCESS: {
      return {
        ...state,
        bannerData: payload,
      };
    }
    case BANNER_FAILED: {
      return {
        ...state,
        error: payload,
      };
    }
    default:
      return state;
  }
};

export default banner;
