import { lazy } from "react";
import GridListSkeleton from "./GridListSkeleton";
import CardSkeleton from "./CardSkeleton";
// import NetworkError from './NetworkError'

const lazyDelayed = (path, delay = 100) => {
  return lazy(() =>
    Promise.all([
      path(),
      new Promise((resolve) => setTimeout(resolve, delay)), // ensures minimal delay
    ]).then(([module]) => module)
  );
};

const Banner = lazyDelayed(() => import("./Banner"));
const Bikes = lazyDelayed(() => import("./Bikes"));
const ChangePassword = lazyDelayed(() => import("./ChangePassword"));
const DatePicker = lazyDelayed(() => import("./DatePicker"));
const DatePickerByDate = lazyDelayed(() => import("./DatePickerByDate"));
const DialogForgot = lazyDelayed(() => import("./DialogForgot"));
const DialogInfo = lazyDelayed(() => import("./DialogInfo"));
const Header = lazyDelayed(() => import("./Header"));
const LoaderButton = lazyDelayed(() => import("./LoaderButton"));
const LogOutDialog = lazyDelayed(() => import("./LogOutDialog"));
const News = lazyDelayed(() => import("./News"));
const Popular = lazyDelayed(() => import("./Popular"));
const ProductBanner = lazyDelayed(() => import("./ProductBanner"));
const TabsBike = lazyDelayed(() => import("./TabsBike"));
const TabsBooking = lazyDelayed(() => import("./TabsBooking"));
const WrapperNav = lazyDelayed(() => import("./WrapperNav"));
const ForceUpdateDialog = lazyDelayed(() => import("./ForceUpdateDialog"));
const Reviews = lazyDelayed(() => import("./Reviews"));
const BookingCard = lazyDelayed(() => import("./BookingCard"));
// const ExtendFullDay = lazyDelayed(() => import("./ExtendFullDay"));
const DialogUploadPayment = lazyDelayed(() => import("./DialogUploadPayment"));
const Page404 = lazyDelayed(() => import("./Page404"));
const Rating = lazyDelayed(() => import("./Rating"));
const EmptyData = lazyDelayed(() => import("./EmptyData"));

export {
  Banner,
  Bikes,
  ChangePassword,
  DatePicker,
  DialogForgot,
  DialogInfo,
  Header,
  LoaderButton,
  LogOutDialog,
  News,
  Popular,
  ProductBanner,
  TabsBike,
  TabsBooking,
  WrapperNav,
  ForceUpdateDialog,
  Reviews,
  BookingCard,
  // ExtendFullDay,
  Page404,
  Rating,
  DialogUploadPayment,
  DatePickerByDate,
  EmptyData,
  GridListSkeleton,
  CardSkeleton,
  // NetworkError
};
