import { combineReducers } from 'redux';
import auth from './auth';
import product from './product';
import banner from './banner';
import article from './article';
import booking from './booking';

const reducer = combineReducers({
  auth,
  article,
  banner,
  booking,
  product,
});

export default reducer;
