import {
  ARTICLE_PROCESS,
  ARTICLE_SUCCESS,
  ARTICLE_FAILED,
} from "../actionType";

const article = (
  state = {
    articleData: [],
    articleTotal: 0,
    error: false,
  },
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case ARTICLE_PROCESS: {
      return {
        ...state,
      };
    }
    case ARTICLE_SUCCESS: {
      return {
        ...state,
        articleData: payload.data,
        articleTotal: payload.total,
      };
    }
    case ARTICLE_FAILED: {
      return {
        ...state,
        error: payload,
      };
    }
    default:
      return state;
  }
};

export default article;
