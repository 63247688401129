import qs from "qs";
import { ApiCall } from "services/server";

export const login = (data) => {
  return ApiCall({
    url: "/auth/login",
    method: "post",
    data: qs.stringify(data),
  })
    .then((res) => res.data)
    .catch(({ response }) => {
      const { status, values } = response.data;
      const err = { status, values };
      throw err;
    });
};

export const register = (data) => {
  return ApiCall({
    url: "/auth/register",
    method: "post",
    data: qs.stringify(data),
  })
    .then((res) => res.data)
    .catch(({ response }) => {
      const { status, values } = response.data;
      const err = { status, values };
      throw err;
    });
};

export const hasReadInbox = (inboxId) => {
  return ApiCall({
    url: `/inbox/read/${inboxId}/1`,
    method: "post",
  })
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

export const forgotPass = (data) => {
  return ApiCall({
    url: "auth/forgot/password",
    method: "post",
    data: qs.stringify(data),
  })
    .then((res) => res.data)
    .catch(({ response }) => {
      const { status, values } = response.data;
      const err = { status, values };
      throw err;
    });
};

export const getUserData = () => {
  return ApiCall({
    url: "/profile",
    method: "get",
  })
    .then((res) => res.data)
    .catch(({ response }) => {
      const { status, values } = response.data;
      const err = { status, values };
      throw err;
    });
};

export const getInbox = (limit =  10) => {
  const params = {
    page: 1,
    length: limit,
    "order[column]": "created_at",
    "order[direction]": "desc",
    "columns[id]": "",
    "columns[source]": "",
    "columns[title]": "",
    "columns[content]": "",
    "columns[is_read]": "",
    "columns[created_at]": "",
  };

  return ApiCall({
    url: "/inbox",
    method: "get",
    params,
  })
    .then((res) => res.data)
    .catch(({ response }) => {
      const { status, values } = response.data;
      const err = { status, values };
      throw err;
    });
};

export const getDetailInbox = (inboxId) => {
  return ApiCall({
    url: `/inbox/${inboxId}`,
    method: "get",
  })
    .then((res) => res.data)
    .catch(({ response }) => {
      let status = response?.data?.status || "";
      let message = response?.data?.values || "";
      const err = { status, message };
      throw err;
    });
};

export const getUnreadInbox = () => {
  const params = {
    read: 0,
  };

  return ApiCall({
    url: "/inbox/check",
    method: "get",
    params,
  })
    .then((res) => res.data)
    .catch(({ response }) => {
      const { status, values } = response.data;
      const err = { status, values };
      throw err;
    });
};

export const editProfile = (data) => {
  return ApiCall({
    url: "/profile",
    method: "put",

    data: qs.stringify(data),
  })
    .then((res) => res.data)
    .catch(({ response }) => {
      const { status, values } = response.data;
      const err = { status, values };
      throw err;
    });
};

export const changeProfilePicture = (data) => {
  return ApiCall({
    url: "/profile/picture",
    method: "put",
    data: data,
  })
    .then((res) => res.data)
    .catch(({ response }) => {
      const { status, values } = response.data;
      const err = { status, values };
      throw err;
    });
};

export const changePassword = (data) => {
  return ApiCall({
    url: "/auth/change/password",
    method: "post",
    data: qs.stringify(data),
  })
    .then((res) => res.data)
    .catch(({ response }) => {
      const { status, values } = response.data;
      const err = { status, values };
      throw err;
    });
};
