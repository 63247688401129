import Cookies from 'js-cookie';

import {
  LOGIN_PROCESS,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  RESET_AUTH,
  REGISTER_PROCESS,
  REGISTER_SUCCESS,
  REGISTER_FAILED,
  PROFILE_REQUEST,
  PROFILE_SUCCESS,
  PROFILE_ERROR,
} from "../actionType";

const auth = (
  state = {
    userData: {},
    error: false,
  },
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case LOGIN_PROCESS: {
      return {
        ...state,
        error: false,
      };
    }
    case LOGIN_SUCCESS: {
      return {
        ...state,
        userData: {...payload},
      };
    }
    case LOGIN_FAILED: {
      return {
        ...state,
        error: payload,
      };
    }

    case RESET_AUTH: {
      Cookies.remove('token');
      return {
        ...state,
        userData: {},
        errors: {}
      };
    }
    case REGISTER_PROCESS: {
      return {
        ...state,
      };
    }
    case REGISTER_SUCCESS: {
      return {
        ...state,
      };
    }
    case REGISTER_FAILED: {
      return {
        ...state,
        error: payload,
      };
    }

    case PROFILE_REQUEST: {
      return {
        ...state,
      };
    }
    case PROFILE_SUCCESS: {
      return {
        ...state,
        userData: payload,
      };
    }
    case PROFILE_ERROR: {
      return {
        loading: false
      };
    }

    default:
      return state;
  }
};

export default auth;
