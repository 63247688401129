import axios from "axios";
import Cookies from 'js-cookie';

const { REACT_APP_GATEAWAY_API } = process.env;

const ApiCall = axios.create({
  baseURL: REACT_APP_GATEAWAY_API,
  headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
});

ApiCall.interceptors.request.use(request => {
  const token = Cookies.get('token')
  if (token) {
    request.headers.Authorization = `Bearer ${token}`
  }
  return request
})

ApiCall.interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    const { response } = err;

    if (response.status === 403 && response.config.url !== "/reservation/payment" && response.config.url !== "/comment") {
      Cookies.remove('token')
      window.location.replace('/signin')
    }

    return Promise.reject(err);
  }
);

export { ApiCall };
