import { set as setCookies } from 'js-cookie';

import {
  LOGIN_PROCESS,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  RESET_AUTH,

  // REGISTER_PROCESS,
  // REGISTER_SUCCESS,
  // REGISTER_FAILED,

  PROFILE_REQUEST,
  PROFILE_SUCCESS,
  PROFILE_ERROR,
} from '../actionType';

import { login, getUserData } from 'services/authService'

const loginAction = {
  process: () => ({
    type: LOGIN_PROCESS
  }),
  success: payload => {
    return {
    type: LOGIN_SUCCESS,
    payload: payload
  }},
  failed: err => ({
    type: LOGIN_FAILED,
    payload: err
  })
};


export const signinUser = ({ email, password }) => async (dispatch) => {
  dispatch(loginAction.process());
  return login({ email, password })
    .then(async (res) => {
      const token = res?.values?.token
      setCookies('token', token, { path: '/' });

      const response = await getUserData();
      dispatch(loginAction.success(response.values));

      return res.value;
    })
     .catch((err) => {
      const errMsg = err.values.errors?.password?.[0] || err.values.errors || err.values.email 
      dispatch(loginAction.failed(errMsg));
      throw errMsg;
    });
};

export const signoutUser = () => (
   {
    type: RESET_AUTH
  });


  const profileAction = {
    process: () => ({
      type: PROFILE_REQUEST
    }),
    success: data => ({
      type: PROFILE_SUCCESS,
      payload: data
    }),
    failed: () => ({
      type: PROFILE_ERROR
    })
  };

  export const getProfile = () => async (dispatch) => {
    dispatch(profileAction.process());
    try {
      const response = await getUserData();
      dispatch(profileAction.success(response.values));
    } catch (error) {
      dispatch(profileAction.failed());
    }
  };