import styled from "styled-components";

import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";

export const GridListItem = styled(GridListTile)`
  && {
    width: 160px !important;
    min-height: 254px !important;
    height: 100% !important;
    background: #212428;
    :last-child {
      padding-right: 24px !important;
    }

    > div {
      border-radius: 8px;
      background: #212428;
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.06);
      display: flex;
      flex-direction: column;
    }
  }
`;

export const ImageWrapper = styled.div`
  height: 134px;
  > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`;

export const PopularInfo = styled.div`
  /* border: solid 1px #2a2e37; */
  padding: 12px 0px;
`;

export const GridListStyled = styled(GridList)`
  && {
    flex-wrap: nowrap;
    transform: translateZ(0);
  }
`;

export const PopularWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  overflow: hidden;
  padding-left: 16px;
`;
