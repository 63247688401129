export const LOGIN_PROCESS = 'LOGIN_PROCESS';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';

export const RESET_AUTH = 'RESET_AUTH';

export const REGISTER_PROCESS = 'REGOSTER_PROCESS';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILED = 'REGISTER_FAILED';

export const PROFILE_REQUEST = 'PROFILE_REQUEST';
export const PROFILE_SUCCESS = 'PROFILE_SUCCESS';
export const PROFILE_ERROR = 'PROFILE_ERROR';

export const PRODUCT_PROCESS = 'PRODUCT_PROCESS';
export const PRODUCT_SUCCESS = 'PRODUCT_SUCCESS';
export const PRODUCT_FAILED = 'PRODUCT_FAILED';


export const PRODUCT_POPULAR_PROCESS = 'PRODUCT_POPULAR_PROCESS';
export const PRODUCT_POPULAR_SUCCESS = 'PRODUCT_POPULAR_SUCCESS';
export const PRODUCT_POPULAR_FAILED = 'PRODUCT_POPULAR_FAILED';

export const BRAND_PROCESS = 'BRAND_PROCESS';
export const BRAND_SUCCESS = 'BRAND_SUCCESS';
export const BRAND_FAILED = 'BRAND_FAILED';

export const BRAND_TYPE_PROCESS = 'BRAND_TYPE_PROCESS';
export const BRAND_TYPE_SUCCESS = 'BRAND_TYPE_SUCCESS';
export const BRAND_TYPE_FAILED = 'BRAND_TYPE_FAILED';

export const BANNER_PROCESS = 'BANNER_PROCESS';
export const BANNER_SUCCESS = 'BANNER_SUCCESS';
export const BANNER_FAILED = 'BANNER_FAILED';

export const ARTICLE_PROCESS = 'ARTICLE_PROCESS';
export const ARTICLE_SUCCESS = 'ARTICLE_SUCCESS';
export const ARTICLE_FAILED = 'ARTICLE_FAILED';

export const SET_BOOKING_DATE = 'SET_BOOKING_DATE';
