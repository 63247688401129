import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import reducerRoot from 'redux/reducers';

export default function configureStore(initialState) {

  // disable redux devtools in production
  const composeEnhancers = process.env.NODE_ENV !== 'production' &&
    typeof window !== 'undefined' &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ :
      compose;

  const store = createStore(
    reducerRoot,
    initialState,
    composeEnhancers(applyMiddleware(thunk))
  );

  if (module.hot) {
    module.hot.accept('redux/reducers', () => { // Enable Webpack hot module replacement for reducers
      store.replaceReducer(reducerRoot);
    });
  }

  return store;
}
