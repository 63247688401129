import { createMuiTheme } from "@material-ui/core/styles";

const mainCss = createMuiTheme({
  overrides: {
    MuiMenu: {
      paper: {
        backgroundColor: "#2a2e37",
        color: "#ffffff",
      },
    },
    MuiListItem: {
      container: {
        background: "#2A2E37",
        borderRadius: 8,
        marginBottom: 12,
      },
    },
    MuiListItemText: {
      primary: {
        fontSize: "14px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      },
      secondary: {
        fontSize: "12px",
        lineHeight: "15px",
        color: "#999999",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        paddingRight: 38,
      },
    },
    MuiDialog: {
      paper: {
        background: "#202328",
      },
    },
    MuiTypography: {
      body1: {
        fontSize: "14px",
      },
      h6: {
        fontSize: "16px",
      },
      colorInherit: {
        color: "#FFFFFF",
      },
      caption: {
        color: "#FFFFFF !important",
      },
      root: {
        color: "#FFFFFF",
      },
    },
    MuiFormControlLabel: {
      root: {
        marginBottom: "18px",
      },
    },
    MuiCheckbox: {
      root: {
        color: "#999999",
        "&$checked": {
          color: "#F42140 !important",
        },
      },
    },
    MuiInputLabel: {
      outlined: {
        color: "#999999",
      },
    },
    MuiFormLabel: {
      root: {
        "&$focused": {
          color: "#FFFFFF",
        },
        "&$disabled": {
          color: "#999999",
        },
      },
    },
    MuiOutlinedInput: {
      input: {
        "-internal-autofill-selected": {
          backgroundColor: "red !important",
        },
        "&:-webkit-autofill": {
          WebkitBoxShadow: "0 0 0 30px #202328 inset",
          WebkitTextFillColor: "#FFFFFF",
        },
        "&$disabled": {
          color: "#FFFFFF",
          borderColor: "#606060 !important",
        },
      },
      root: {
        marginBottom: "16px",
        color: "#FFFFFF",
        borderRadius: "8px",
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: "#FFFFFF",
        },
        "&$focused $notchedOutline": {
          borderColor: "#FFFFFF",
        },
        "&$disabled $notchedOutline": {
          borderColor: "#606060",
        },
      },
      notchedOutline: {
        borderColor: "#999999",
      },
    },
    MuiSelect: {
      outlined: {
        color: "#FFFFFF",
      },
      iconOutlined: {
        color: "#FFFFFF",
      },
      select: {
        color: "#FFFFFF",
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: "#999999",
      },
    },
    MuiTab: {
      textColorPrimary: {
        color: "#999999",
        "&$selected": {
          color: "#F42140",
        },
      },
      wrapper: {
        textTransform: "capitalize",
        fontSize: "16px",
      },
    },
    MuiTabs: {
      root: {
        background: "#212428",
      },
      indicator: {
        display: "flex",
        justifyContent: "center",
        backgroundColor: "transparent",
        "& > div": {
          width: "100%",
          margin: "0 11px",
          backgroundColor: "#F42140",
        },
      },
      scrollButtons: {
        color: "#e01d24",
      },
    },
    MuiSvgIcon: {
      root: {
        // color: 'unset'
      },
    },
    MuiToolbar: {
      root: {
        "&&": {
          width: "100%",
          margin: "0 auto",
          maxWidth: "602px",
        },
      },
    },
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: "#2a2e37",
      },
    },
    MuiIconButton: {
      root: {
        background: "transparent !important",
      },
    },
    MuiRadio: {
      root: {
        color: "#999999",
      },
    },
    MuiFormHelperText: {
      contained: {
        color: "#999999",
        marginBottom: 16,
        marginLeft: 0,
        marginTop: -8,
      },
    },
  },
});

export default mainCss;
