import React, { Suspense, useState, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import "./App.css";

import { Routes } from "router/routerConfig";
import { WrapperNav, ForceUpdateDialog, Page404 } from "components";

const pjson = require("../package.json");

const App = () => {
  const [open, setOpen] = useState(false);

  // force update app
  const handleUpdate = () => {
    localStorage.removeItem("app-version");
    window.location.reload();
  };

  useEffect(() => {
    const currentVersion = localStorage.getItem("app-version");
    if (!currentVersion) {
      localStorage.setItem("app-version", pjson.version);
    }
    if (currentVersion !== null && currentVersion !== pjson.version) {
      setOpen(true);
    }
  }, []);

  return (
    <Suspense
      fallback={
        <div className="loaderWrapper">
          <CircularProgress color="secondary" />
        </div>
      }
    >
      <WrapperNav>
        <Container maxWidth="sm" style={{ padding: 0 }}>
          <Switch>
            {Object.keys(Routes).map((key, index) => (
              <Route
                exact={true}
                key={index}
                path={Routes[key].path}
                component={Routes[key].component}
              />
            ))}
            <Route component={Page404} />
          </Switch>
        </Container>
      </WrapperNav>
      <ForceUpdateDialog open={open} handleUpdate={handleUpdate} />
    </Suspense>
  );
};

export default App;
