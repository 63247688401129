import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";


import {
  GridListItem,
  ImageWrapper,
  PopularInfo,
  GridListStyled,
  PopularWrapper,
} from "./index.sc";

const GridListSkeleton = (props) => {
  return (
    <SkeletonTheme color="#2a2e37" highlightColor="#212429">
      <PopularWrapper>
        <GridListStyled children={<div />} spacing={16}>
          {[1, 2, 3, 4].map((item, index) => (
            <GridListItem key={`${item.id} ${index}`}>
              <ImageWrapper>
                <Skeleton height="100%" width="100%" />
              </ImageWrapper>
              <PopularInfo>
                <Skeleton count={4} />
              </PopularInfo>

              <Skeleton width="70px" />

            </GridListItem>
          ))}
        </GridListStyled>
      </PopularWrapper>
    </SkeletonTheme>
  );
};

export default GridListSkeleton;
