import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import globaleMUI from "utils/globalMUI";
import { SnackbarProvider } from "notistack";
import { ThemeProvider } from "@material-ui/core/styles";
import getInitialState from 'utils/getInitialState';

import getStore from 'redux/store';

import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";


const initState = window.__PRELOADED_STATE__;
delete window.__PRELOADED_STATE__;
const store = getStore(initState); // Create a theme instance.
store.dispatch(getInitialState());

ReactDOM.render(
  <ThemeProvider theme={globaleMUI}>
    <SnackbarProvider
    maxSnack={1}
    autoHideDuration={2500}
    hideIconVariant
    anchorOrigin={{ horizontal: "right", vertical: "top" }}
  >
    <Provider store={store}>
      <BrowserRouter location={window.location.pathname} context={{}}>
        <App />
      </BrowserRouter>
    </Provider>
    </SnackbarProvider>
  </ThemeProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
