import styled from "styled-components";

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';


export const CardStyled = styled(Card)`
  && {     
    display: flex;
    background: none;
    box-shadow: none;
    height: 110px;
    margin-bottom: 16px;
  }
`

export const CardContentStyled = styled(CardContent)`
  && {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
`


export const CardMediaStyled = styled.div`
  && {     
    width: 110px;
  }
`

export const ContentWrapper = styled.div`
  display: flex;
  width: calc(100% - 110px);
  flex-direction: column;
`


