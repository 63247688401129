import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

import {
  ContentWrapper,
  CardContentStyled,
  CardMediaStyled,
  CardStyled,
} from "./index.sc";

const News = (props) => {
  return (
    <SkeletonTheme color="#2a2e37" highlightColor="#212429">
      <div>
        {[1, 2, 3 ].map((index) => {
          return (
            <CardStyled key={index}>
              <CardMediaStyled>
                <Skeleton height="105px" style={{ borderRadius: 8 }} />
              </CardMediaStyled>

              <ContentWrapper>
                <CardContentStyled>
                  <Skeleton width="80%" />
                  <Skeleton width="95%" />
                  <Skeleton count={2} />
                </CardContentStyled>
              </ContentWrapper>
            </CardStyled>
          );
        })}
      </div>
    </SkeletonTheme>
  );
};

export default News;
